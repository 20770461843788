/* import __COLOCATED_TEMPLATE__ from './conversation-details.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import type InboxApi from 'embercom/services/inbox-api';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { enqueueTask, task } from 'ember-concurrency-decorators';
import type InboxState from 'embercom/services/inbox-state';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import type Snackbar from 'embercom/services/snackbar';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';
import type ConditionalAttributesService from 'embercom/services/conditional-attributes-service';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import { taskFor } from 'ember-concurrency-ts';
import { DataType } from 'embercom/objects/inbox/conversation-attribute-descriptor';

interface Args {
  conversation: Conversation;
  conversationAttributeDescriptors: ConversationAttributeDescriptor[];
  isOpen: boolean;
  name: string;
  onOpenSectionChange: () => unknown;
  autofocusTitle?: boolean;
  onConversationOrChecklistSelected?: () => void;
  previewOnly?: boolean;
  isPreviewingConversation?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: SidebarSectionComponentArgs<Args>;
}

export default class ConversationDetails extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare conditionalAttributesService: ConditionalAttributesService;

  @tracked visibleToUser = true;
  @trackedReset('args.conversation.id') titleInput = this.args.conversation.title || '';
  @trackedReset('args.conversation.id') formSubmitted = false;

  get conditionalAttributesEvaluator() {
    return this.conditionalAttributesService.initialize(
      this.args.conversationAttributeDescriptors,
      this.conversationAttributesById,
      this.updateAttributeOptimistically.bind(this),
    );
  }

  conversationAttributesDescriptors() {
    if (!this.session.workspace.canUseConditionalAttributes) {
      return this.args.conversationAttributeDescriptors;
    }

    return this.conditionalAttributesEvaluator.updateVisibility();
  }

  get visibleConversationAttributeDescriptors() {
    return this.conversationAttributesDescriptors()?.filter(
      (descriptor) =>
        !descriptor.archived &&
        !descriptor.hideFromInboxSidebar &&
        descriptor.isVisibleToTeamId(this.args.conversation.teamAssignee?.id),
    );
  }

  get conversationAttributesById() {
    return this.args.conversation.attributes?.reduce(
      (byIds, attribute) => {
        byIds[attribute.descriptor.id] = attribute;
        return byIds;
      },
      {} as Record<string, ConversationAttributeSummary>,
    );
  }

  attributeValueById(id: string) {
    let allAttributes = this.conversationAttributesById;
    let specificAttribute = allAttributes[id];
    if (specificAttribute) {
      return specificAttribute.value;
    }
    return undefined;
  }

  get hasCreatedByValue() {
    return this.attributeValueById('created_by_teammate_id');
  }

  get showConversationSubject(): boolean {
    return !this.args.conversation.isTicket;
  }

  get header(): string {
    return this.intl.t(
      'inbox.conversation-details-sidebar.section-headings.conversation-attributes',
    );
  }

  @action async onUpdateAttribute(attribute: ConversationAttributeSummary) {
    let { conversation } = this.args;

    if (this.session.workspace.canUseOptimisticConversationAttributes) {
      return await this.updateAttributeOptimistically(attribute);
    }

    if (!conversation.hasAttributeForDescriptor(attribute.descriptor.id)) {
      conversation.addAttribute(attribute);
    }

    return await this.inboxApi.updateAttribute(conversation.id, attribute);
  }

  async updateAttributeOptimistically(attribute: ConversationAttributeSummary) {
    let { conversation } = this.args;
    let update = this.conversationUpdates.addUpdate(
      conversation.id,
      'conversation-attribute-update',
      { attribute, displayValue: this.getDisplayValue(attribute) },
    );
    this.conditionalAttributesEvaluator.recalculateDependentAttributesOptions(attribute);

    taskFor(this.persistAttribute)
      .perform(conversation.id, attribute, update.part.clientAssignedUuid)
      .catch(() => {
        update.rollback(conversation);
        let updates = this.conversationUpdates.updatesFor(this.args.conversation.id);
        this.conversationUpdates.rollbackUpdates(conversation.id, updates);

        this.snackbar.notifyError(
          this.intl.t(
            'inbox.conversation-details-sidebar.attributes.errors.updating-user-attribute',
            { attributeName: attribute.descriptor.name },
          ),
        );
      })
      .finally(() => {
        if (this.session.workspace.canUseConditionalAttributes) {
          this.conditionalAttributesEvaluator.updateVisibility(attribute);
        }
      });
  }

  private getDisplayValue(attribute: ConversationAttributeSummary): string | undefined {
    let { type, id } = attribute.descriptor;

    if (attribute.value === null || ![DataType.Boolean, DataType.List].includes(type)) {
      return undefined;
    }

    if (attribute.descriptor.type === DataType.Boolean) {
      return this.intl.t(
        `inbox.conversation-attributes.boolean.${attribute.value ? 'true' : 'false'}`,
      );
    }

    let descriptor = this.args.conversationAttributeDescriptors.find((x) => x.id === id);
    return (
      descriptor?.listOptions?.find((option) => option.id === attribute.value)?.label || undefined
    );
  }

  @enqueueTask({ maxConcurrency: 1 })
  *persistAttribute(
    conversationId: number,
    attribute: ConversationAttributeSummary,
    clientAssignedUuid: string | undefined,
  ) {
    yield this.inboxApi.updateAttribute(conversationId, attribute, clientAssignedUuid);
  }

  @action toggleVisibleToUser() {
    this.visibleToUser = !this.visibleToUser;
  }

  @task({ keepLatest: true })
  *updateConversationTitle() {
    let previousTitle = this.args.conversation.title;
    let newTitle = this.titleInput?.trim();

    if (newTitle === previousTitle) {
      return;
    }
    yield this.inboxState.updateConversationTitle(this.args.conversation, newTitle, previousTitle);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::ConversationDetails': typeof ConversationDetails;
    'inbox2/conversation-details-sidebar/conversation-details': typeof ConversationDetails;
  }
}
